.navbar {
  background-color: #f8f9fa;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  width: 100%;
  box-sizing: border-box;
}

/* Add padding to body to prevent content from hiding under navbar */
body {
  padding-top: 70px;
  margin: 0;
  width: 100%;
  overflow-x: hidden;
}

.nav-links {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.nav-link {
  text-decoration: none;
  color: #333;
  padding: 0.5rem;
  border-radius: 4px;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.nav-link:hover {
  background-color: #e9ecef;
}

.nav-link.active {
  background-color: #007bff;
  color: white;
}

.logout-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
}

.logout-btn:hover {
  background-color: #c82333;
}

.logout-btn:active {
  transform: scale(0.98);
}

/* Hamburger menu icon - hidden by default */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 18px;
  cursor: pointer;
  padding: 0;
  background: none;
  border: none;
  margin: 0;
}

.hamburger span {
  display: block;
  width: 24px;
  height: 2px;
  background-color: #333;
  border-radius: 2px;
  transition: all 0.3s ease;
}

/* Tablet Styles */
@media screen and (max-width: 768px) {
  .navbar {
    padding: 1rem 0.5rem;
  }
  
  .nav-links {
    gap: 0.25rem;
  }
  
  .nav-link {
    padding: 0.4rem 0.5rem;
    font-size: 0.85rem;
  }

  .logout-btn {
    padding: 0.4rem 0.5rem;
    font-size: 0.85rem;
  }
}

/* Mobile Styles - switch to hamburger */
@media screen and (max-width: 480px) {
  .navbar {
    padding: 0.75rem;
    min-height: 50px;
    justify-content: flex-start; /* Align hamburger to left */
  }

  .hamburger {
    display: flex;
    z-index: 9999;
    margin-right: 1rem;
  }

  /* Hamburger animation when active */
  .hamburger.active span:first-child {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .hamburger.active span:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active span:last-child {
    transform: rotate(-45deg) translate(5px, -5px);
  }

  .nav-links {
    display: none;
    position: fixed;
    top: 50px; /* Match navbar height */
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: #f8f9fa;
    padding: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 9998;
    width: 100%;
    box-sizing: border-box;
  }

  /* Show menu when active */
  .nav-links.active {
    display: flex;
  }

  .nav-link {
    width: 100%;
    text-align: center;
    padding: 0.75rem;
    font-size: 1rem;
    margin: 0.25rem 0;
    box-sizing: border-box;
  }

  .logout-btn {
    width: 100%;
    margin: 0.5rem 0;
    padding: 0.75rem;
    box-sizing: border-box;
  }

  /* Adjust body padding for mobile */
  body {
    padding-top: 50px;
  }
}

/* Small mobile devices */
@media screen and (max-width: 360px) {
  .navbar {
    padding: 0.5rem;
  }
  
  .nav-link, .logout-btn {
    font-size: 0.9rem;
    padding: 0.6rem;
  }
} 