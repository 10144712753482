.admin-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Admin dashboard navigation */
.admin-nav {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.admin-nav-button {
  padding: 10px 20px;
  background-color: #a7a6a6;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.admin-nav-button:hover {
  background-color: #e0e0e0;
}

.admin-nav-button.active {
  background-color: #3498db;
  color: white;
}

.admin-content {
  margin-top: 20px;
}

/* Workout view specific styles */
.workout-view {
  width: 100%;
}

.date-selector {
  margin: 20px 0;
  text-align: center;
  
}

.workouts-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.workout-plan-card {
  width: 100%;
  background: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.client-info {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.client-info h3 {
  margin: 0 0 5px 0;
}

.client-email, .trainer-email {
  display: block;
  color: #666;
  font-size: 0.9em;
  margin-bottom: 5px;
}

.status {
  display: inline-block;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  margin-top: 5px;
}

.status.completed {
  background: #e6ffe6;
  color: #006600;
}

.status.pending {
  background: #fff3e6;
  color: #cc7700;
}

.workout-details {
  margin-top: 15px;
}

.details-header {
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr);
  font-weight: bold;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.exercise-row {
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr);
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.exercise-name {
  font-weight: 500;
}

.muscle-group {
  color: #666;
  font-size: 0.9em;
  margin-left: 5px;
}

.workout-notes {
  margin-top: 1rem;
  padding: 1rem;
  background-color: var(--background-color, #fff);
  border-radius: 0.375rem;
  border: 1px solid var(--border-color-light, #f0f0f0);
}

.workout-notes h4 {
  margin: 0 0 0.5rem 0;
  color: var(--text-primary, #333);
  font-size: 0.875rem;
  font-weight: 600;
}

.workout-notes p {
  margin: 0;
  white-space: pre-wrap;
  color: var(--text-secondary, #6c757d);
  font-size: 0.875rem;
  line-height: 1.5;
}

.workout-time {
  margin-top: 15px;
  color: #666;
  font-size: 0.9em;
}

.loading, .no-workouts {
  text-align: center;
  padding: 40px;
  color: #666;
}

.trainers-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 30px;
  max-width: 800px;
  margin: 30px auto 0;
}

.trainer-section {
  width: 100%;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
}

/* User Management View styles */
.user-management-view {
  width: 100%;
}

.users-container {
  margin-top: 20px;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}

.users-table th,
.users-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.users-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.users-table tr:hover {
  background-color: #f9f9f9;
}

.action-button {
  padding: 6px 12px;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.8em;
}

.action-button.edit {
  background-color: #3498db;
  color: white;
}

.action-button.delete {
  background-color: #e74c3c;
  color: white;
}

.no-users {
  text-align: center;
  padding: 40px;
  color: #666;
}

/* Workout actions */
.workout-actions {
  display: flex;
  gap: 10px;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

.action-button.toggle {
  background-color: #f39c12;
  color: white;
}

/* Edit modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.edit-modal {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.edit-modal h3 {
  margin-top: 0;
  margin-bottom: 15px;
}

.notes-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
  font-family: inherit;
  margin-bottom: 15px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.modal-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.modal-button.cancel {
  background-color: #e0e0e0;
}

.modal-button.save {
  background-color: #2ecc71;
  color: white;
}

.modal-button:disabled,
.action-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
} 